body{
    font-family: 'Open Sans', sans-serif;
}
.mai-login {
    background: #ECEEF3;
    overflow: auto;
}
.mai-login .splash-container {
    padding-top: 0px;
        width: 100%;
        height:100vh;
        margin: 0 auto;
        /*background-color: #072667;*/
    }
    .mai-login .splash-container .user-message {
        background-color: transparent;
        padding-left:15px;
    }
    .mai-login .splash-container .login-form-main {
        background: #072667 url('../../Assets/Images/bg-pattern.svg') no-repeat bottom center;
    }
    .mai-login .splash-container .login-form-main .form-message {
        background-color: rgb(7 38 103 / 70%);
        padding-top: 0px;
    }
    .mai-login .login-form-main .form-message .ant-spin-container img.logo-img {
        /* padding-bottom: 90px; */
    }
    .img-fluid {
        max-width: 100%;
        height: auto
    }
    .color-red {
        color: red;
    }
    .logo-center{
        text-align: center;
    }
    .login-footer-logo-center{
        text-align: center;
        bottom: 10px;
    position: relative;
    width: 100%;
    color: white;
    left:0px;
    }
    .login-validation{
        color: red;
        position: absolute;
        font-size: 11px;
        margin-top: -15px;
    }
    .logo-top-space{
        padding-top: 80px;
    }
    .padding-space{
        padding-right: 30px;
    }
    .login-content-width{
        width: 50%;
    }
    .login-footer-content-width{
        width: 70%;
        margin-left: 40px;
        
    }
    .login-content{
        padding-left: 60px;
    }
    .login-footer-image{
        margin-left: -10px;
        /* width: 70%; */
    }
    .forgot-password-style{
        cursor: pointer;
        font-size:13px;
        font-weight: 600;
        color: #0096FF;
      }
      .forgot-password-style:hover {
        color: #0078cc;
      }
    .login-btns{
        width: 100%;
        font-size: 1.5rem !important;
        font-weight: 700 !important;
        text-transform: uppercase;
    }
   .login-input-field{
    height: 3rem !important;
    font-size: 12px;
    border-radius: 0px 50px 50px 0px !important;
   }
   .login-forgot-password{
    margin-top:-20px;
   }
   .login-input-group{
    /* padding: 6px ;
    display: table-cell;
     */
     position: relative;
    display: table;
    border-collapse: separate;
    padding: 10px 0 10px 0 ;
    /* width:70%;
    margin-left:15%; */
   }
   .logo-tag-line{
    font-family: 'Open Sans', sans-serif;
    font-size: x-large;
    margin: 30px 0 25px 0;
   }
   .logo-title {
    font-weight: 700;
    font-size: 3.5rem;
    color: #0096FF;
   }
   .logos-img {
       max-width: 500px;
       height: auto;
   }
     /* --------------Media queries------------------------- */
    @media screen and (max-width: 767px) {
    }
    
  