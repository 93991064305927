.breadcrumbs{
    color: #253d77;
    padding: 0px 0px 20px 0px;
}
.grop-name {
  color: gray;
}
.breadcrumbs a:hover {
  color: #0096FF;
}
.download-content{
    background-color: #f2f4f8;
    border-radius: 5px;
    display: flex;
    flex-wrap: wrap;
  }
  .download-content:hover {
    background-color: #fff;
  }
  .download-icon-postion{
    background-color: #072667;
    margin: 10px;
    padding: 10px;
    border-radius: 2px;
  }
  .download-text-space{
    margin:10px;
  }
  .download-text-heading{
    color:#253d77;
    font:bold;
  }
  .download-text-description{
    color: grey;
    font-size: 11px;
    word-wrap: break-word;
  }
  .video-page-extra-margin{
    margin-top:30px;
  }
  .video-navigation{
    cursor: pointer;
  }
  .page-controls{
    position: absolute;
    bottom: 5%;
    left: 50%;
    background: white;
    opacity: 0;
    transform: translateX(-50%);
    transition: opacity ease-in-out 0.2s;
    box-shadow: 0 30px 40px 0 rgba(16, 36, 94, 0.2);
    border-radius: 4px;
    /* display: none; */
  }
  .react-pdf__Document:hover .page-controls {
    opacity: 1;
  }
  .current-video-item {
    background-color: #fff;
  }