.group-container {
    background-color: #f2f4f8;
    border-radius: 2px;
    padding-top: 15px;
    cursor: pointer;
    min-height: 410px;
    transition: all 0.3s ease;
}
.accordion__button[aria-expanded='true'] .group-container {
  background-color: #fff;
  box-shadow: 1px 7px 11px 2px rgb(0 0 0 / 11%);
}
.accordion__button[aria-expanded='true'] .arrow-icon svg {
  background-color: #76B305;
  color: #fff;
  border-color: #76B305;
}
.grey-out-container::before,
.sub-group-container.grey-out-container::before,
.accordion__button[aria-expanded='true'] .group-container.grey-out-container::before  {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
	background-color: rgb(220, 224, 233, 0.5);
}
.sub-group-container {
    background: #fff;
    box-shadow: 1px 7px 11px 2px rgba(0,0,0,0.11);
    max-height: 400px;
    overflow-y: scroll;
}

.video-content-container{
    border-radius: 2px;
    background: #f2f4f8;
    cursor: pointer;
  }
  
  .video-content-container:hover {
    background: #ECEEF4;
  }
  .group-img{
    width: 88%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
  }
  .video-img{
    width: 88%;
    height: auto;
    border-radius: 5px;
    cursor: pointer;
  }
  .MuiFormControlLabel-root{
    margin-left: -25px!important;
    margin-right: -11px !important;
  }
  .download-link{
    background-color: #072667;
    color: white;
    padding: 1px 10px 2px 10px;
    margin-top: 5px;
    border-radius: 20px;
    display: inline-block;
    font-size: 13px;
  }
  .completed-link{
    background-color: #76B305;
    color: white;
    padding: 1px 10px 2px 10px;
    display: inline-block;
    border-radius: 20px;
    margin-top: 5px;
    font-size: 13px;
  }
  .group-right-detail{
    /* position: relative !important; */
      top: 10px;
      font-size: larger;
  }
/* Isa revamp */
.course-title {
	color: #072667;
}
.arrow-icon {
  color: #76B305;
}
.arrow-icon svg {
  width: 25px !important;
  height: 25px;
  padding: 3px;
  border: 2px solid;
  color: #76B305;
  border-radius: 50px;
}
.blue-color {
  color: #0096FF;
}
.green-color {
  color: #76B305;
}
.grey-out-container{
  
}