body{
    font-family: 'Open Sans', sans-serif;
}
.logo-tag-line{
    font-family: 'Open Sans', sans-serif;
    font-size: x-large;
    margin: 30px 0 25px 0;
   }

    .mai-login .splash-container .user-message {
        background-color: transparent;
        padding-left:15px;
    }
    .img-fluid {
        max-width: 100%;
        height: auto
    }
    .color-red {
        color: red;
    }
    .logo-center{
        text-align: center;
       
        
    }
    .footer-logo-center{
        text-align: center;
        bottom: 10px;
    position: relative;
    width: 100%;
    left:0px;
    color: white; 
    }
    .logo-top-space{
        padding-top: 80px;
    }
    .padding-space{
        padding-right: 30px;
    }
    .login-content-width{
        width: 50%;
    }
    .login-footer-content-width{
        width: 70%;
        margin-left: 40px;
        
    }
    .login-content{
        left: 70px;
        padding-left: 60px;
    }
    .login-footer-image{
        margin-left: -10px;
        /* width: 70%; */
    }
    .registration-content-container{
         position: relative;
        width: 80%;
        margin-left: 10%;
    }
   .check-font{
       font-size: 11px;
   }
   .registration-btn{
       width:100% ;
       border-radius: 2px;
   }
   .back-btn{
    background-color: white;
    border-radius: 2px;
    color:black;
}

.input-icon-style{
    padding: 7px 14px;
    font-size: 14px;
    font-weight: 400;
    line-height: 1;
    color: #fff;
    text-align: center;
    background-color: #808FB0;
    width: 1%;
    white-space: nowrap;
    vertical-align: middle;
    display: table-cell;
    border-radius: 50px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  
}
.registration-input-group{
    position: relative;
    display: table;
    border-collapse: separate;
    padding: 10px 0 10px 0 ;
}

.left-icon{
    text-align:left;
    display: grid;
    padding-left:10px;
    position:absolute;
}
.modal-style {
    background-color: #fff;
    border-radius: 5px;
    max-Width: 500;
    min-Height: 300;
    margin: 0 auto;
    padding: 30px;
    padding: 20px;
    width: 50%;
    position: fixed;
    left: 25%;
    top: 20%;
    text-align: center;
    font-family: sans-serif;
    color: black;
  }
  .modal-btn{
      color: white;
      background-color:#253d77;
      width: 100%;
      border-radius: 2px;
      border: 0px;
      padding: 10px;
      margin-top: 10px;
  }
  .backdrop-style {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.3);
    padding: 50;
    z-index: 1;
  }
.registration-input-field{
    height:3rem !important;
    border-radius: 0px 50px 50px 0px !important;
    
}
.registration-select-input{
    padding:0px !important;
}
.form-control:focus {
border-color: aliceblue ;
}
.registration-font{
    font-family: inherit !important;

}
.registration-checkbox{
    margin-left: 0px !important;
    position: absolute;
    display: contents !important;
}
.registration-validation{
    color: red;
    font-size: 12px;
    margin-top: -12px;
    line-height: 16px !important;
}
.registration-hyper-link{
    color:#0096FF;
}
.registration-hyper-link:hover{
    color:#0078cc;
}
.registration-remove-space{
    padding-left: 0px !important;
}
/* Check boxes */
.checkbox-light .MuiCheckbox-root {
    color: #fff !important;
}
.MuiCheckbox-root {
    color: #0096FF !important;
}
.MuiIconButton-colorPrimary {
    color: #0096FF !important;
    padding: 0 10px 0 5px !important;
}
.PrivateSwitchBase-root-1 {
    padding: 0 10px 0 5px !important;
}

     /* --------------Media queries------------------------- */
    @media screen and (max-width: 767px) {

    }
    
  