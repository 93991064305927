.feature-video {
	background: rgba(7, 39, 103, .4);
}
.feature-video:hover {
	background: rgb(0, 150, 255, .25);
	cursor: pointer;
}
.feature-video p {
	line-height: 1.2;
  font-size: 12px;
}
.video-thumbnail {
	height: auto;
	width: 100%;
}
.carousel-control-next, .carousel-control-prev {
  width: auto;
}
.carousel-inner {
  padding: 1rem;
  min-height: 145px;
}
/* Feature ribbon */
.ribbon {
  width: 130px;
  height: 130px;
  overflow: hidden;
  position: absolute;
	z-index: 9;
}

.ribbon span {
  position: absolute;
  display: block;
  width: 225px;
  padding: 7px 0;
  background-color: #76B305;
  box-shadow: 0 5px 10px rgba(0,0,0,.1);
  color: #fff;
  font: 700 12px/1 sans-serif;
	letter-spacing: 1px;
  text-shadow: 0 1px 1px rgba(0,0,0,.2);
  text-transform: uppercase;
  text-align: center;
}
.ribbon-top-right {
  top: -4px;
  right: -4px;
}
.ribbon-top-right::before,
.ribbon-top-right::after {
  border-top-color: transparent;
  border-right-color: transparent;
}
.ribbon-top-right::before {
  top: 0;
  left: 0;
}
.ribbon-top-right::after {
  bottom: 0;
  right: 0;
}
.ribbon-top-right span {
  left: -25px;
  top: 30px;
  transform: rotate(45deg);
}
