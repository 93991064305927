body {
    font-family: 'OpenSans-Light';
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}
.search-video-grey-out{
    opacity: 0.5;
    cursor: not-allowed !important;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.search-bar {
    border-width: 2px !important;
    border-radius: 30px;
    border-color: #bdd0da !important;
    background-color: transparent !important;
}

.search-bar-icon {
    color: #76B305 !important;
}

.layout-logo {
    height: 30px;
    width: 150px;
    margin-top: -5px;
}

::placeholder {
    color: #bdd0da !important;
}

.dashboard-container {
    padding-top: 0px !important;
}

.container-width {
    width: 50%;
}

.group-level-first {
    /* border:1px black solid; */
    border-radius: 2px;
    padding: 5px;
    margin: 10px 0px 10px 0px;
    background: aliceblue;
}

.previous-video {
    width: 200px;
    min-height: 150px;
    background: #fff;
    border-radius: 6px;
    padding: 8px;
}

.next-video {
    width: 200px;
    min-height: 150px;
    border-radius: 6px;
    background: #fff;
    padding: 8px;
}
.previous-video,
.next-video:hover {
    background: rgba(118, 179, 6, 0.7);
}
.sub-video-container {
    height: 55px;
    border-radius: 2px;
    background-position: center, center;
    background-repeat: no-repeat;
    min-height: 95px;
    width: auto;
    /* background-image:  url('http://dev-s3-tabnu-training-client.s3-website-eu-west-1.amazonaws.com/thumbnail/Dual axis chart.PNG') ; */
}

.icon-bg-color {
    background-color: white;
}

.bg-gray7 {
    background-color: #495057;
}

.bg-blue7 {
    background-color: #053463;
}

.white {
    color: #fff;
}

.justify-center {
    justify-content: center;
}

.items-center {
    align-items: center;
}

.flex-column {
    flex-direction: column;
}

.flex {
    display: flex;
}

.tabnu-logo {
    width: 260px !important;
}

.apply-hand {
    cursor: pointer;
    border-radius: 2px !important;
}

.dropdown-toggle:after {
    display: inline-block !important;
}
.footer {
    background: #072767;
    overflow: auto;
}
.footer-left-section {
    color: #fff;
}

.footer-right-section {
    float: right;
    padding: 8px;
}

.dashboard-sub-group-right-panel {
    font-size: 10px;
    color: grey;
}

.dashboard-language {
    color: #fff;
    margin-top: 24px;
    margin-right: 3px;
}

.completed-video {
    background-color: blue;
    color: white;
    padding: 1px 5px 2px 5px;
    border-radius: 50%;
}

.dashboard-footer-logo {
    height: 30px;
    margin-bottom: 15px;
}

.dashboard-group-seperator {
    color: #ACB6CF;
    border-top: 1px dashed;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.search-input {
    margin: auto;
    border-radius: 50px !important;
    border-color: #bdd0da !important;
}

.sub-group-description {
    font-size: 12px;
    cursor: pointer;
}

.sub-description {
    cursor: pointer;
    font-size: 13px;
    line-height: 17px;
}

.dropdown-menu {
    border: 1px grey solid !important;
    background-color: white !important;
    color: black !important;
}

.right-banner-img {
    margin: 15% 0 15% 0;
    ;
}

.container {
    font-family: sans-serif;
}

.top-space {
    margin-top: 25px;
}

.header-left-logo {
    right: 30px;
}

.loader-style {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    text-align: center;
    padding: 50px;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url(./Assets/Fonts/Open_Sans/OpenSans-Light.ttf);
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url(./Assets/Fonts/Open_Sans/OpenSans-Bold.ttf);
}

@font-face {
    font-family: 'OpenSans-Light';
    src: url(./Assets/Fonts/Montserrat/Montserrat-Light.ttf) format('ttf');
}

@font-face {
    font-family: 'OpenSans-Bold';
    src: url(./Assets/Fonts/Montserrat/Montserrat-Bold.ttf) format('ttf');
}